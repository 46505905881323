import React, { Fragment, Component } from "react";
import VideoPostGridItem from "./VideoPostGridItem";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
import styled from "styled-components";

const Div = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr 1.5em 1fr;
  grid-template-columns: 1;
  grid-template-rows: 1fr;
  grid-gap: 1.5em;
  @media (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr;
    -ms-grid-columns: 1fr 2em 1fr;
    grid-gap: 2em;

    .blog-item:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }
    .blog-item:nth-child(2) {
      -ms-grid-row: 1;
      -ms-grid-column: 3;
    }
    .blog-item:nth-child(3) {
      -ms-grid-row: 3;
      -ms-grid-column: 1;
    }
    .blog-item:nth-child(4) {
      -ms-grid-row: 3;
      -ms-grid-column: 3;
    }
    .blog-item:nth-child(5) {
      -ms-grid-row: 1;
      -ms-grid-row: 5;
    }
    .blog-item:nth-child(6) {
      -ms-grid-row: 5;
      -ms-grid-column: 3;
    }
  }
`;
class BlogGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPageId: 1
    };
  }

  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }

  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id
      };
    });
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case "Prev":
        this.changePage(selectedPageId - 1);
        break;
      case "Next":
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    // this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? "selected-page" : ""}`}
      >
        {id}
      </button>
    );
  };

  render() {

    return (
      <Div className="blog-grid">
        {this.props.posts.map(({ node }, index) => {
          return (
            <VideoPostGridItem
              key={node.slug}
              item={node}
              className="blog-item"
            />
          );
        })}
      </Div>
    );
  }
}

export default BlogGrid;
