import React, { Component } from 'react';
import styled from 'styled-components';
import { colors, fonts } from '../style-utilities/variables';
// components
import WidgetItem from './WidgetItem';
import EmailSignupForm from './EmailSignupForm';

const StyledGridWidget = styled.div`
  text-align: center;
  border: 2px solid ${colors.secondaryGray};
  margin-bottom: 1.5rem;
  padding: 21.5% 0;
  h4 {
    font-family: ${fonts.header};
    text-transform: uppercase;
  }
  p {
    color: ${colors.primaryGray};
    letter-spacing: 0.25px;
  }
  
`;

class GridWidget extends Component {
  render() {
    switch (this.props.type) {
      case 'newsletter':
        return (
          <StyledGridWidget className="grid-widget">
            <h3>Get Intel From Ansira</h3>
            <EmailSignupForm formatType="white-button"/>
          </StyledGridWidget>
        );
      case 'most_popular':
        return (
          <StyledGridWidget className="grid-widget">
            most_popular
          </StyledGridWidget>
        );
      default:
        return (
          <StyledGridWidget className="grid-widget">
            <h4>Most Popular</h4>
          </StyledGridWidget>
        );
    }
  }
}

export default GridWidget;
