import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import format from 'date-fns/format';
import { colors } from '../style-utilities/variables';
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
// import components
import Tag from './Tag';

const StyledWidgetItem = styled.div`
  display: block;
  background-color: #ffffff;
  margin: 0px -20px;
  padding: 0px 20px 20px 20px;
  
  .tag{
    margin-bottom: 8px;
    font-weight: bold;
  }

  p {
    margin-bottom: 8px;
  }

  .widget-box {
    text-align: left;
    border-bottom: 1px solid ${colors.primaryGray};
    padding-bottom: 10px;
  }

  .first-widget-box {
    padding-top: 20px;
  }

  &.last-widget-box {
    padding-bottom: 0px;
    border-radius: 0px 0px 5px 5px;
  }

  &.last-widget-box .widget-box {
    border-bottom: none;
  }
  
  .date {
    color: ${colors.primaryGray};
    font-size: 14px;
    letter-spacing: .03em;
  }

  .title {
    color: ${colors.primaryGray};
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .02em;
  }

  a {
    color: ${colors.primaryGray};
    font-size: 14px;
    letter-spacing: .02em;
    text-decoration: underline;
  }
`;

const WidgetItem = ({ item, index }) => {

  const postTypes = {
    post: {
      label: 'Blog',
      color: colors.white,
      borderColor: colors.primaryGray,
      textColor: colors.black
    },
    white_paper: {
      label: 'White Paper',
      color: colors.white,
      borderColor: colors.primaryGray,
      textColor: colors.black
    }
  };

  // format date for card
  const formattedDate = format(new Date(item.date), 'MMMM D, YYYY');
  // used to display appropriate type info in Tag component
  const tagInfo = postTypes[item.type];

  return (
    <StyledWidgetItem className={`${index === 4 ? "last-widget-box" : ""}`}>
      <div className={`widget-box ${index === 0 ? "first-widget-box" : ""} $`}>
        <Tag tag={tagInfo.label} color={tagInfo.color} borderColor={tagInfo.borderColor} textColor={tagInfo.textColor} />
        <p className="date">{formattedDate}</p>
        <p className="title">{item.title}</p>
        <p><a href={item.slug}>Read More</a></p>
      </div>
    </StyledWidgetItem>
  );
};

export default WidgetItem;
