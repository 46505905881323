import React, { Component } from 'react';
import { graphql } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../style-utilities/variables";
import _ from "lodash";
import Link from "gatsby-link";

// components
import SEO from "../components/SEO";
import Hero from "../components/Hero";
import InsightsGrid from "../components/InsightsGrid/InsightsGrid";
import Pagination from "../components/Pagination";
import ContactBanner from "../components/ContactBanner";
import GridWidget from "../components/GridWidget";
import MostPopularPostWidget from "../components/MostPopularPostWidget";
import SecondaryNavRes from "../components/SecondaryNavRes";
import DefaultSharingImage from "../static/ansira-logo-sharing.png";
import FiltersVideos from '../components/FiltersVideos';
import TinyPagination from '../components/TinyPagination';
import Arrow from '../components/Arrow';
import ArrowLeft from '../components/ArrowLeft';
import VideoGrid from '../components/Videos/VideoGrid';


const StyledInsightsIndex = styled.main`
  margin: 0.75em auto;
  max-width: 1500px;
  .no-posts {
    text-align: center;
  }
  .counter-container {
    display: none;
  }
`;


class VideoPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setSortValue: {},
      setTopicValue: {},
      setServiceValue: {},
      setVerticalValue: {},
      setVideoTypeValue: {},
      sortValue: '',
      topicValue: '',
      topicId: '',
      serviceValue: '',
      serviceId: '',
      verticalValue: '',
      videoTypeValue: '',
      valueId: '',
      isBrowser: typeof window !== 'undefined',
      selectedPageId: 1
    }

    this.changePage = this.changePage.bind(this);
    this.renderBtnNumber = this.renderBtnNumber.bind(this);
  }

  /**
   * METHOD: Scroll to top on pagination click needs scrollStep
   */
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }
  scrollToTop() {
    let intervalId = setInterval(
      this.scrollStep.bind(this),
      this.props.delayInMs
    );
    this.setState({ intervalId: intervalId });
  }
  /**
   * METHOD: Change page on Pagination
   * @param: id of the current page
   */
  changePage(id) {
    this.setState(prevState => {
      return {
        ...prevState,
        selectedPageId: id
      };
    });
  }
  /**
   * METHOD: for button Prev and Next on Pagination
   * @param: id of the current page
   */
  buttonPageClick(id) {
    let { selectedPageId } = this.state;
    switch (id) {
      case '<':
        this.changePage(selectedPageId - 1);
        break;
      case '>':
        this.changePage(selectedPageId + 1);
        break;
      default:
        this.changePage(id);
        break;
    }
    this.scrollToTop();
  }
  /**
   * METHOD: Render button to the UI
   * @param: id of the current page
   */
  renderBtnNumber = id => {
    let { selectedPageId } = this.state;

    let buttonContent = id;
    if (id == '<') {
      buttonContent = <ArrowLeft />;
    }
    if (id == '>') {
      buttonContent = <Arrow />;
    }

    return (
      <button
        onClick={this.buttonPageClick.bind(this, id)}
        key={id}
        className={`page ${selectedPageId === id ? 'selected-page' : ''}`}
      >
        {buttonContent}
      </button>
    );
  };

  /* Get Topic Service and Vertical value from filter and store for pagination*/
  handleVideo = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        'handleVideo',
        e ? JSON.stringify(e) : window.localStorage.removeItem('handleVideo')
      );
    }
    this.setState({
      videoTypeValue: e ? e.value : null,
      setVideoTypeValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleTopic = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        'handleTopic',
        e ? JSON.stringify(e) : window.localStorage.removeItem('handleTopic')
      );
    }
    this.setState({
      topicValue: e ? e.value : null,
      setTopicValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleService = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        'handleService',
        e ? JSON.stringify(e) : window.localStorage.removeItem('handleService')
      );
    }
    this.setState({
      serviceValue: e ? e.value : null,
      setServiceValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleVertical = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        'handleVertical',
        e ? JSON.stringify(e) : window.localStorage.removeItem('handleVertical')
      );
    }
    this.setState({
      verticalValue: e ? e.value : null,
      setVerticalValue: e ? e : null,
      selectedPageId: 1
    });
  };
  handleSort = e => {
    if (this.state.isBrowser) {
      window.localStorage.setItem(
        'handleSort',
        e ? JSON.stringify(e) : window.localStorage.removeItem('handleSort')
      );
    }
    this.setState({
      sortValue: e ? e.value : null,
      setSortValue: e ? e : null
    });
  };

render() {
  const props = this.props;
  const tags = props.tags;
  const videos_page = props.data.wordpressPage;
  const title = props.data.wordpressPage.acf.hero_headline;
  const subtitle = props.data.wordpressPage.acf.hero_sub_headline;

  let topics = props.data.allWordpressWpTopic;
  let service = props.data.allWordpressWpOurSolutions;
  let vertical = props.data.allWordpressWpVertical;
  let types = props.data.allWordpressWpTypes;
  let video_types = props.data.allWordpressWpVideoType;

  let filteredPosts = [];
  const topicsList = [];
  const servicesList = [];
  const verticalsList = [];
  const videosTypeList = [];
  let usedTopics = [];
  let usedServices = [];
  let usedVerticals = [];
  let usedVideosType = [];
  let selectedValuesArray = [];
  let queryParameter = '';
  let queryNumber = '';
  let topicPlaceholder = 'Topic';
  let posts = props.data.allWordpressWpVideos.edges;

  /*
    Start filter logic -------------------------------------------------
  */
  if (typeof document !== 'undefined') {
    queryParameter = window.location.href.split('=');
    queryNumber = parseInt(queryParameter[1]);
  }

  if (queryParameter.length > 1) {
    selectedValuesArray.push(parseInt(queryParameter[1]));
  }

  //if item in Array of selected state matches items in single post, push that post to a new posts array and send to Blog Grid
  if (this.state.topicValue != '' && this.state.topicValue != null) {
    selectedValuesArray.push(this.state.topicValue);
  }
  if (this.state.serviceValue != '' && this.state.serviceValue != null) {
    selectedValuesArray.push(this.state.serviceValue);
  }
  if (this.state.verticalValue != '' && this.state.verticalValue != null) {
    selectedValuesArray.push(this.state.verticalValue);
  }
  if (this.state.videoTypeValue != '' && this.state.videoTypeValue != null) {
    selectedValuesArray.push(this.state.videoTypeValue);
  }


  posts.map((post, i) => {
    let thisPostsValues = [];

    posts[i].node.topic.map((topic, j) => {
      usedTopics.push(topic);
      thisPostsValues.push(posts[i].node.topic[j]);
      if (
        posts[i].node.topic[j] == this.state.topicValue &&
        this.state.serviceValue == '' &&
        this.state.verticalValue == '' &&
        this.state.videoTypeValue == ''
      ) {
        filteredPosts.push(post);
      }
    });

    posts[i].node.our_solutions.map((services, k) => {
      usedServices.push(services);
      thisPostsValues.push(posts[i].node.our_solutions[k]);
      if (
        posts[i].node.our_solutions[k] == this.state.serviceValue &&
        this.state.topicValue == '' &&
        this.state.verticalValue == '' &&
        this.state.videoTypeValue == ''
      ) {
        filteredPosts.push(post);
      }
    });

    posts[i].node.vertical.map((vertical, l) => {
      usedVerticals.push(vertical);
      thisPostsValues.push(posts[i].node.vertical[l]);
      if (
        posts[i].node.vertical[l] == this.state.verticalValue &&
        this.state.serviceValue == '' &&
        this.state.topicValue == '' &&
        this.state.videoTypeValue == ''
      ) {
        filteredPosts.push(post);
      }
    });

    posts[i].node.video_type.map((type, d) => {
      usedVideosType.push(type);
      thisPostsValues.push(posts[i].node.video_type[d]);
      if (
        posts[i].node.video_type[d] == this.state.verticalValue &&
        this.state.serviceValue == '' &&
        this.state.topicValue == '' &&
        this.state.videoTypeValue == ''
      ) {
        filteredPosts.push(post);
      }
    });
    if (
      selectedValuesArray.every(function(item) {
        return thisPostsValues.indexOf(item) !== -1;
      }) == true
    ) {
      filteredPosts.push(post);
    }
  });

  //Check if no results on filter to trigger GTM event
  if (typeof document !== 'undefined') {
    let arrPostItems = document.getElementsByClassName('insights-grid');
    let postItem = arrPostItems.item(0);
    if (postItem != null && postItem.innerHTML === '') {
      if (filteredPosts.length === 0) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'noFilterResults'
        });
      }
    }
  }

  //function to remove duplicates in filteredPosts array
  function removeDuplicates(arr) {
    const final = [];
    arr.map((e, i) => !final.includes(e) && final.push(e));
    return final;
  }

  removeDuplicates(usedTopics);
  removeDuplicates(usedServices);
  removeDuplicates(usedVideosType);
  filteredPosts = removeDuplicates(filteredPosts);

  let allUsedTopics = [];
  let allUsedServices = [];
  let allUsedVideoTypes = [];

  topics.edges.map((topic, index) => {
    for (var i = 0; i < usedTopics.length; i++) {
      if (usedTopics[i] == topics.edges[index].node.wordpress_id) {
        allUsedTopics.push(topic);
      }
    }
  });
  service.edges.map((serv, index) => {
    for (var i = 0; i < usedServices.length; i++) {
      if (usedServices[i] == service.edges[index].node.wordpress_id) {
        allUsedServices.push(serv);
      }
    }
  });
  video_types.edges.map((type, index) => {
    for (var i = 0; i < usedVideosType.length; i++) {
      if (usedVideosType[i] == video_types.edges[index].node.wordpress_id) {
        allUsedVideoTypes.push(type);
      }
    }
  });


  for (var key in topics.edges) {
    if (topics.edges[key].node.slug == this.state.topicValue) {
      this.setState({ topicValue: topics.edges[key].node.wordpress_id });
    }
    if (topics.edges[key].node.wordpress_id == queryNumber) {
      topicPlaceholder = topics.edges[key].node.name;
    }
  }

  for (var key in service.edges) {
    if (service.edges[key].node.slug == this.state.serviceValue) {
      this.setState({ serviceValue: service.edges[key].node.wordpress_id });
    }
  }
  for (var key in vertical.edges) {
    if (vertical.edges[key].node.slug == this.state.verticalValue) {
      this.setState({ verticalValue: vertical.edges[key].node.wordpress_id });
    }
  }
  for (var key in video_types.edges) {
    if (video_types.edges[key].node.slug == this.state.videoTypeValue) {
      this.setState({ videoTypeValue: video_types.edges[key].node.wordpress_id });
    }
  }

  //sort all posts by most popular
  let popularPosts = [];
  if (this.state.sortValue == 'most-popular') {
    popularPosts = Object.assign([], posts).sort(function(a, b) {
      return b.node.acf.popularity_num - a.node.acf.popularity_num;
    });
    posts = popularPosts;
  } else {
    posts = props.data.allWordpressWpVideos.edges;
  }

  //sort filtered posts by most popular
  let popularFiltered = [];
  if (this.state.sortValue == 'most-popular' && filteredPosts != 0) {
    popularFiltered = Object.assign([], filteredPosts).sort(function(a, b) {
      return b.node.acf.popularity_num - a.node.acf.popularity_num;
    });
    filteredPosts = popularFiltered;
  } else if (this.state.sortValue == 'most-recent' && filteredPosts != 0) {
    filteredPosts = filteredPosts;
  }

  // Here we are grabbing content for filter dropdowns, the react-select library requires a specific format so we need to rename (name, slug) to be (value, label)
  removeDuplicates(allUsedTopics).forEach((item, index) => {
    topicsList[index] = {
      value: item.node.slug,
      label: item.node.name
    };
  });

  // allUsedServices = allUsedServices.map((service, index) => {
  //   {ReactHtmlParser(allUsedServices[index].node.name)}
  // });

  removeDuplicates(allUsedServices).forEach((item, index) => {
    servicesList[index] = {
      value: item.node.slug,
      label: item.node.name
    };
  });

  removeDuplicates(allUsedVideoTypes).forEach((item, index) => {
    videosTypeList[index] = {
      value: item.node.slug,
      label: item.node.name
    };
  });

  props.data.allWordpressWpVertical.edges.forEach((item, index) => {
    verticalsList[index] = {
      value: item.node.slug,
      label: item.node.name
    };
  });

  const itemPerPage = 6;
  let defaultPosts = [...posts];
  defaultPosts = defaultPosts.splice(
    (this.state.selectedPageId - 1) * itemPerPage,
    itemPerPage
  );
  let conditionalPosts = removeDuplicates(filteredPosts);
  conditionalPosts = conditionalPosts.splice(
    (this.state.selectedPageId - 1) * itemPerPage,
    itemPerPage
  );

  /*
    End ---------------------------------------------------------------
  */
      return (
          <StyledInsightsIndex className="animate">
            <SEO
              isBlogPost={false}
              postData={videos_page}
              postImage={DefaultSharingImage}
            />
            <Hero headline={title} sub={subtitle} />

            <div className="post-area page-container">

            <FiltersVideos
              setTopicValue={
                this.state.setTopicValue !== null &&
                Object.getOwnPropertyNames(this.state.setTopicValue).length !== 0
                  ? this.state.setTopicValue
                  : null
              }
              setServiceValue={
                this.state.setServiceValue !== null &&
                Object.getOwnPropertyNames(this.state.setServiceValue).length !==
                  0
                  ? this.state.setServiceValue
                  : null
              }
              setVerticalValue={
                this.state.setVerticalValue !== null &&
                Object.getOwnPropertyNames(this.state.setVerticalValue).length !==
                  0
                  ? this.state.setVerticalValue
                  : null
              }
              setVideoTypeValue={
                this.state.setVideoTypeValue !== null &&
                Object.getOwnPropertyNames(this.state.setVideoTypeValue).length !==
                  0
                  ? this.state.setVideoTypeValue
                  : null
              }
              topics={topicsList}
              topicPlaceholder={topicPlaceholder}
              services={servicesList}
              verticals={verticalsList}
              types={videosTypeList}
              changeSort={this.handleSort}
              changeTopic={this.handleTopic}
              changeService={this.handleService}
              changeVertical={this.handleVertical}
              changeType={this.handleVideo}
              />




            {filteredPosts.length === 0 && selectedValuesArray.length == 0 ? (
              <>
                <VideoGrid posts={defaultPosts} tags={tags} />
                <TinyPagination
                  total={posts.length}
                  selectedPageId={this.state.selectedPageId}
                  itemPerPage={itemPerPage}
                  renderBtnNumber={this.renderBtnNumber}
                  maxBtnNumbers={5}
                  preKey="<"
                  nextKey=">"
                />
              </>
            ) : (
              <div>
                {filteredPosts.length > 0 && conditionalPosts.length > 0 ? (
                  <div>
                    <VideoGrid posts={conditionalPosts} tags={tags} />
                    {filteredPosts > conditionalPosts ? (
                      <TinyPagination
                        total={filteredPosts.length}
                        selectedPageId={this.state.selectedPageId}
                        itemPerPage={itemPerPage}
                        renderBtnNumber={this.renderBtnNumber}
                        maxBtnNumbers={4}
                        preKey="<"
                        nextKey=">"
                      />
                    ) : (
                      <TinyPagination
                        total={filteredPosts.length}
                        selectedPageId={this.state.selectedPageId}
                        itemPerPage={itemPerPage}
                        renderBtnNumber={this.renderBtnNumber}
                        maxBtnNumbers={4}
                        preKey="<"
                        nextKey=">"
                      />
                    )}
                  </div>
                ) : (
                  <div className="no-posts">
                    <p>
                      Sorry there are no blog posts with the selected filters.
                    </p>
                  </div>
                )}
              </div>
            )}

            </div>
            <ContactBanner
              headline={videos_page.acf.contact_headline}
              cta={videos_page.acf.contact_cta}
              url={videos_page.acf.contact_url}
            />
          </StyledInsightsIndex>
        );
      };

}
export default VideoPosts;

export const query = graphql`
  query {
    allWordpressWpVideos(sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          content
          slug
          date(formatString: "MMMM D, YYYY")
          acf {
            seo_canonical
            seo_description
            seo_title
            display_date
            video
            popularity_num
            excerpt
            primary_topic
          }
          better_featured_image {
            source_url
          }
          topic
          vertical
          our_solutions
          video_type
        }
      }
    }
wordpressPage(slug: {eq: "videos"}) {
  content
  slug
  title
  type
  content
  acf {
    hero_headline
    hero_sub_headline
    overview_headline
    contact_headline
    contact_cta
    contact_url
    seo_title
    seo_canonical
    seo_description
  }
}
allWordpressWpTopic {
  edges {
    node {
      name
      slug
      wordpress_id
    }
  }
}
allWordpressWpOurSolutions {
  edges {
    node {
      name
      slug
      wordpress_id
    }
  }
}
allWordpressWpVertical {
  edges {
    node {
      name
      slug
      wordpress_id
    }
  }
  }
  allWordpressWpVideoType {
    edges {
      node {
        name
        slug
        wordpress_id
      }
    }
    }

  }
`;
