import React from "react";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";
// import components
import InsightsGridItem from "./InsightsGridItem";
import InsightsLandingFeatured from "../../components/InsightsGrid/InsightsLandingFeatured";

const StyledInsightsGrid = styled.div`
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1.5em 1fr;
  -ms-grid-rows: 1fr 1.5em 1fr 1.5em 1fr;
  grid-template-rows: 1fr;
  grid-gap: 1.5em;
  @media (min-width: ${breakpoints.tabletMax}) {
    grid-template-columns: repeat(4, 1fr);
    div:nth-child(odd) {
      grid-column: 1 / span 2;
      -ms-grid-column: 1;
    }
    div:nth-child(even) {
      grid-column: 3 / span 2;
      -ms-grid-column: 3;
    }
    div:nth-child(n + 3) {
      -ms-grid-row: 3;
    }
    div:nth-child(n + 5) {
      -ms-grid-row: 5;
    }
  }
`;

const InsightsGrid = ({ tagsReducer, posts, featured }) => {
  return (
    <div className="insights-grid">
      <InsightsLandingFeatured />
      <StyledInsightsGrid>
        {posts.map(({ node }, index) => {
          return <InsightsGridItem key={node.slug} item={node} index={index} />;
        })}
      </StyledInsightsGrid>
    </div>
  );
};

export default InsightsGrid;
