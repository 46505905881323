import styled from "styled-components";
import { Link } from "gatsby";
import { colors, breakpoints, fonts } from "../../style-utilities/variables";

export default styled.div`
  background: ${colors.white};
  border: 2px solid ${colors.secondaryGray};
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 auto;
  :hover {
    text-decoration: none;
    color: ${colors.black};
  }
  .image-box {
    background-color: ${colors.white};
    width: 100%;
    position: relative;
    float: left;
    overflow: hidden; /* need overflow: hidden for ie  */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0;
    img {
      margin-bottom: 0;
    }
  }
  .featured-container {
    position: absolute;
    top: 20px;
    p {
      background: white;
      padding: 7px 30px;
      font-size: 14px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: black;
      font-weight: bold;
      letter-spacing: 3px;
    }
  }
  .post-details {
    flex-grow: 1;
    padding: 1em;
    background-color: ${colors.white};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      height: 80%;
    }
  }
  .post-category {
    margin-bottom: 0;
  }
  .post-header {
    margin-bottom: .5rem;
  }

  .title-link {
    color: ${colors.black};
  }
  .tag {
    margin-bottom: 0;
    color: ${colors.blue};
    display: inline-block;
    font-weight: bold;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  @media (min-width: ${breakpoints.tablet}) {
    & {
      .img-container {
        height: 53%;
      }
    }

    /* styling for items with no featured image */
    &.no-img {
      align-items: center;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .post-excerpt {
    flex: 1;
    p {
      margin-bottom: 1em;
    }
  }
`;
