import React from 'react';
import styled, { consolidateStreamedStyles } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import format from 'date-fns/format';
import { colors, fonts } from '../style-utilities/variables';
import Arrow from './Arrow';

const StyledWidget = styled.div`
  .popular-container {
    border: 2px solid ${colors.secondaryGray};
    padding: 18px;
    h4 {
      margin: 10px 10px 0;
      font-family: ${fonts.header};
      text-transform: uppercase;
    }
    .widget-box {
      display: flex;
      padding: 14px 10px;
      text-align: left;
      border-bottom: 2px solid ${colors.secondaryGray};
      padding-bottom: 10px;
      .title {
        margin-right: 10px;
        a {
          color: black;
          letter-spacing: 0.02em;
        }
      }
    }

    .widget-box:first-of-type {
      padding-top: 20px;
    }

    .widget-box:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
    p {
      margin-bottom: 8px;
      color: black;
      font-weight: bold;
    }
  }
`;

const StyledWidgetItem = styled.div``;

const FeaturedPostsWidget = () => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressWpWhitePaper {
          edges {
            node {
              title
              type
              slug
              type
              date
              acf {
                excerpt
                popularity_num
              }
            }
          }
        }
        allWordpressPost {
          edges {
            node {
              title
              type
              slug
              type
              date
              acf {
                excerpt
                popularity_num
              }
            }
          }
        }
      }
    `}
    render={data => {
      const picksList = [
        ...data.allWordpressPost.edges,
        ...data.allWordpressWpWhitePaper.edges
      ].sort(function(a, b) {
        return b.node.acf.popularity_num - a.node.acf.popularity_num;
      });

      picksList.length = 5;

      const postTypes = {
        post: {
          label: 'Blog',
          color: colors.white,
          borderColor: colors.primaryGray,
          textColor: colors.black
        },
        white_paper: {
          label: 'White Paper',
          color: colors.white,
          borderColor: colors.primaryGray,
          textColor: colors.black
        }
      };

      return (
        <StyledWidget>
          <div className="popular-container">
            <h4>Most Popular</h4>
            {picksList.map(({ node }) => {
              let tagInfo = postTypes[node.type],
                pathPrefix =
                  node.type === 'post'
                    ? '/insights/blog/'
                    : '/insights/white-papers/',
                formattedDate = format(new Date(node.date), 'MMMM D, YYYY');
              return (
                <div className="widget-box">
                  <p className="title">
                    <a href={pathPrefix + node.slug}>{node.title} </a>
                  </p>
                  <p className="arrow-link">
                    <a href={pathPrefix + node.slug}>
                      <Arrow />
                    </a>
                  </p>
                </div>
              );
            })}
          </div>
        </StyledWidget>
      );
    }}
  />
);

export default FeaturedPostsWidget;
