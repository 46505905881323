import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { colors, breakpoints } from "../style-utilities/variables";

import Arrow from "../components/Arrow";

const StyledNav = styled.div`
max-width: 1500px;
a {
  font-weight: 700;
  margin-right: 12px;
  color: ${colors.primary};
  svg {
    margin-left: 2px;
    width: 18px;
  }
}

`;
const SecondaryNavRes = props => {
  return (
    <StyledNav>
      <Link to="/insights/blog">
        Blog <Arrow />
      </Link>
      <Link to="/insights/white-papers">
        White Papers <Arrow />
      </Link>
    </StyledNav>
  );
};

export default SecondaryNavRes;
