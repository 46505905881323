import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import { colors, breakpoints } from '../style-utilities/variables';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faList } from '@fortawesome/free-solid-svg-icons';

// PLUGIN
import Select from 'react-select';

// COMPONENTS
import BlogGrid from './Blog/BlogGrid';

// ********************* //
// OPTIONS FOR DROPDOWNS
// ********************* //

const sortCriteria = [
  { value: 'most-recent', label: 'Most Recent' },
  { value: 'most-popular', label: 'Most Popular' }
];

// ********************* //
// STYLED COMPONENTS
// ********************* //

const StyledFilterContainer = styled.div`
  padding: 0 0 2em;
  .verticals {
    margin-bottom: 1em;
  }
  .filters-wrapper {
    display: flex;
    flex-direction: column;
  }

  .dropdown {
    flex-grow: 1;
    svg {
      color: ${colors.primaryGray}
    }
    span {
      display: none;
    }
    .css-151xaom-placeholder {
      font-style: italic;
    }
    .css-16pqwjk-indicatorContainer {
      color: black;
    }
  }
  .topics-container {
    .dropdown {
      width: 100%;
    }
  }
  .filter-label {
    font-weight: bold;
    width: 72px;
  }
  .sort-label {
    font-weight: bold;
    width: 60px;
  }

  .filter-options,
  .sort-container,
  .topics-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }
  .filter-options {
    margin-left: 60px;
  }

  .sort-container .dropdown {
    min-width: 160px;
  }
  @media (min-width: 641px) {
    .sort-label,
    .filter-label {
      width: auto;
      margin-right: 0.5em;
    }
    .sort-label {
      margin-left: 1em;
    }
    .verticals,
    .filter-options,
    .sort-container,
    .topics-container {
      margin-bottom: 0;
    }
    .filters-wrapper {
      flex-direction: row;
      justify-content: space-between;
    }
    .topics-container {
      width: calc(25% + 50px);
    }
    .filter-options {
      width: 25%;
      padding-bottom: 0;
      margin-left: 1em;
      .dropdown {
        margin-right: 0;
      }
    }
    @media (min-width: ${breakpoints.desktop}) {
      .sort-label {
        margin-left: 2em;
      }
    }
    @media (min-width: ${breakpoints.laptopLG}) {
      .sort-label {
        margin-left: 4em;
      }
    }
  }
`;

class FiltersVideos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClearable: true
    };
  }

  render() {
    const customStyles = {
      menu: (provided, state) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
        borderRadius: '0 0 5px 5px'
      }),
      control: (provided, state) => ({
        ...provided,
        border: `1px solid ${colors.primaryGray}`,
        boxShadow: 'none',
        '&:hover': {
          border: `1px solid ${colors.primaryGray}`
        },
        '&:focus': {
          border: `1px solid ${colors.primaryGray}`
        }
      })
    };
    return (
      <StyledFilterContainer>
        <div className="filters-wrapper">
          <div className="topics-container">
            <div className="filter-label">Filter</div>
            <Select
              styles={customStyles}
              value={this.props.setVideoTypeValue}
              className="dropdown type"
              isClearable={this.state.isClearable}
              name="types"
              placeholder="Type"
              options={this.props.types}
              onChange={e => this.props.changeType(e)}
              isSearchable={false}
            />

          </div>
          <div className="filter-options">
          <Select
            styles={customStyles}
            value={this.props.setTopicValue}
            className="dropdown topics"
            isClearable={this.state.isClearable}
            name="topics"
            placeholder={this.props.topicPlaceholder}
            options={this.props.topics}
            onChange={e => this.props.changeTopic(e)}
            isSearchable={false}
          />

          </div>
          <div className="filter-options">
            <Select
              styles={customStyles}
              value={this.props.setVerticalValue}
              className="dropdown verticals"
              isClearable={this.state.isClearable}
              name="verticals"
              placeholder="Industry"
              options={this.props.verticals}
              onChange={e => this.props.changeVertical(e)}
              isSearchable={false}
            />
          </div>

          <div className="filter-options">
          <Select
            styles={customStyles}
            value={this.props.setServiceValue}
            className="dropdown services"
            isClearable={this.state.isClearable}
            name="services"
            placeholder="Solution"
            options={this.props.services}
            onChange={e => this.props.changeService(e)}
            isSearchable={false}
          />

          </div>

          <div className="sort-container">
            <div className="sort-label">Sort</div>
            <Select
              styles={customStyles}
              className="dropdown sort"
              name="sort"
              options={sortCriteria}
              onChange={e => this.props.changeSort(e)}
              isSearchable={false}
              placeholder="Most Recent"
            />
          </div>
        </div>
      </StyledFilterContainer>
    );
  }
}

export default FiltersVideos;
