import React from "react";
import { Link } from "gatsby";
import format from "date-fns/format";
import { colors } from "../../style-utilities/variables";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
// import components
import Tag from "../Tag";
import ButtonGrey from "../ButtonGrey";

// import styles (styles got a little too long for one file)
import StyledInsightsGridItem from "./InsightsGridItem.styles";

const InsightsGridItem = props => {

  const item = props.item;
  const formattedDate = format(new Date(item.date), "MMMM D, YYYY");

  // truncate longer excerpts
  // const excerpt = _.truncate(item.acf.excerpt, {
  //   length: 100,
  //   omission: " ..."
  // });

  const excerpt = item.acf.excerpt;

  // if post has a featured image return it
  return item.better_featured_image ? (
    <StyledInsightsGridItem>
        {item.type == "post" ? (
            <Link to={"/insights/blog/" + item.slug} className="image-box">
              <img src={item.better_featured_image.source_url} alt={item.title} />
              {props.featured && (
                <div className="featured-container"><p>{props.featured}</p></div>
              )}
            </Link>
        ) : (
          <Link to={"/insights/white-papers/" + item.slug} className="image-box">
            <img src={item.better_featured_image.source_url} alt={item.title} />
            {props.featured && (
              <div className="featured-container"><p>{props.featured}</p></div>
            )}
          </Link>
        )}
      <div className="post-details">
        <p className="post-category">
        {item.type == "post" ? (
          <span className="tag">Blog</span>
        ) : (
          <span className="tag">White Paper</span>
        )}
        </p>
        <p className="post-header">
          {/* if there's an author, show it */}
          {item.author && (
            <span>
              By{" "}
              <Link to={`/blog/author/` + item.author.slug}>
                {item.author.name}
              </Link>{" "}
              | <span className="date">{formattedDate}</span>
            </span>
          )}
          
        </p>
        {item.type == "post" ? (
          <Link to={"/insights/blog/" + item.slug} className="title-link">
            <p className="post-title">{item.title}</p>
          </Link>
        ) : (
          <Link to={"/insights/white-papers/" + item.slug} className="title-link">
            <p className="post-title">{item.title}</p>
          </Link>
        )}
        
        <div className="post-excerpt"><p>{ReactHtmlParser(excerpt)}</p></div>
        <div className="post-link">
        {item.type == "post" ? (
          <ButtonGrey textLabel="Read More" pageLink={"/insights/blog/" + item.slug}>
            Read More
          </ButtonGrey>
        ) : (
          <ButtonGrey textLabel="Read More" pageLink={"/insights/white-papers/" + item.slug}>
            Read More
          </ButtonGrey>
        )}
        </div>
      </div>
    </StyledInsightsGridItem>
  ) : (
    <StyledInsightsGridItem>
      <div className="image-box">
        <p className="featured">{props.featured}</p>
      </div>
      <div className="post-details">
        <p className="post-category">
        {item.type == "post" ? (
          <span className="tag">Blog</span>
        ) : (
          <span className="tag">White Paper</span>
        )}
        </p>
        <p className="post-header">
          {/* if there's an author, show it */}
          {item.author && (
            <span>
              By{" "}
              <Link to={`/blog/author/` + item.author.slug}>
                {item.author.name}
              </Link>{" "}
              |
            </span>
          )}
          <span className="date">{formattedDate}</span>
        </p>
        <p className="post-title">{item.title}</p>
        <div className="post-excerpt"><p>{ReactHtmlParser(excerpt)}</p></div>
        <div className="post-link">
        <ButtonGrey textLabel="Read More" pageLink={"/insights/blog/" + item.slug}>
          Read More
        </ButtonGrey>
        </div>
      </div>
    </StyledInsightsGridItem>
  );
};

export default InsightsGridItem;
