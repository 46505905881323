import React from "react";
import styled from "styled-components";
import { Link, StaticQuery, graphql } from "gatsby";
import { colors, fonts, breakpoints } from "../../style-utilities/variables";
import _ from "lodash";

// import components
import InsightsGridItem from "./InsightsGridItem";

const StyledFeaturedBox = styled.div`
  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  -ms-grid-columns: 1fr 1.5rem 1fr;
  grid-row-gap: 24px;
  margin-bottom: 24px;
  @media (min-width: ${breakpoints.tabletMax}) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1.5rem;
    div:nth-child(odd) {
      -ms-grid-column: 1;
    }
    div:nth-child(even) {
      -ms-grid-column: 3;
    }
  }
`;

const InsightsLandingFeatured = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressPost(filter: { acf: { featured_post: { eq: true } } }) {
            edges {
              node {
                title
                type
                slug
                author {
                  name
                  slug
                }
                date(formatString: "MMMM D, YYYY")
                better_featured_image {
                  alt_text
                  source_url
                }
                acf {
                  featured_post
                  excerpt
                }
              }
            }
          }
          allWordpressWpWhitePaper(
            filter: { acf: { featured_post: { eq: true } } }
          ) {
            edges {
              node {
                title
                type
                slug
                acf {
                  featured_post
                  excerpt
                }
                better_featured_image {
                  alt_text
                  source_url
                }
              }
            }
          }
        }
      `}
      render={data => {
        const featuredBlog = data.allWordpressPost.edges[0].node;
        const featuredPaper = data.allWordpressWpWhitePaper.edges[0].node;

        return (
          <StyledFeaturedBox>
            <InsightsGridItem item={featuredBlog} featured="FEATURED" />
            <InsightsGridItem item={featuredPaper} featured="FEATURED" />
          </StyledFeaturedBox>
        );
      }}
    />
  );
};

export default InsightsLandingFeatured;
